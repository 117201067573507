import 'youtube-background';
import Chart from 'chart.js/auto';
import ChartDeferred from 'chartjs-plugin-deferred';

Chart.register(ChartDeferred);

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    $('.menu-item a').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });

    $('[data-slick]').slick({
      prevArrow: '.prev-arrow',
      nextArrow: '.next-arrow',
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });


    $('.hero-image').slick({
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    });

    // $('[data-vbg]').youtube_background({
    //   'fit-box': true,
    // });

    const labels = [
      '2018',
      '2019',
      '2020',
      '2021',
      '2022',
    ];

    const data1 = {
      labels: labels,
      datasets: [{
        label: '',
        backgroundColor: [
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(88, 26, 78)',
        ],
        borderColor: 'rgb(255, 99, 132)',
        data: [12.80, 14.39, 16.69, 26.34, 32.88],
      }],
    };

    const config1 = {
      type: 'bar',
      data: data1,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y) + 'M';
                }
                return label;
              },
            },
          },
          deferred: {
            yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
            delay: 500,      // delay of 500 ms after the canvas is considered inside the viewport
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    };

    const data2 = {
      labels: labels,
      datasets: [{
        label: '',
        backgroundColor: [
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(88, 26, 78)',
        ],
        borderColor: 'rgb(255, 99, 132)',
        data: [32388, 34202, 35579, 56683, 63192],
      }],
    };

    const config2 = {
      type: 'bar',
      data: data2,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          deferred: {
            deferred: {
              yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
              delay: 500,      // delay of 500 ms after the canvas is considered inside the viewport
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    };

    const data3 = {
      labels: labels,
      datasets: [{
        label: '',
        backgroundColor: [
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(88, 26, 78)',
        ],
        borderColor: 'rgb(255, 99, 132)',
        data: [2.06, 2.33, 2.55, 4.08, 4.75],
      }],
    };

    const config3 = {
      type: 'bar',
      data: data3,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y) + 'B';
                }
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
          deferred: {
            yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
            delay: 500,      // delay of 500 ms after the canvas is considered inside the viewport
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    };

    const data4 = {
      labels: labels,
      datasets: [{
        label: '',
        backgroundColor: [
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(88, 26, 78)',
        ],
        borderColor: 'rgb(255, 99, 132)',
        data: [12.80, 14.39, 16.69, 26.34, 32.88],
      }],
    };

    const config4 = {
      type: 'bar',
      data: data4,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y) + 'M';
                }
                return label;
              },
            },
          },
          deferred: {
            deferred: {
              yOffset: '70%', // defer until 50% of the canvas height are inside the viewport
              delay: 1000,      // delay of 500 ms after the canvas is considered inside the viewport
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    };

    const data5 = {
      labels: labels,
      datasets: [{
        label: '',
        backgroundColor: [
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(88, 26, 78)',
        ],
        borderColor: 'rgb(255, 99, 132)',
        data: [32388, 34202, 35579, 56683, 63192],
      }],
    };

    const config5 = {
      type: 'bar',
      data: data5,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          deferred: {
            yOffset: '70%', // defer until 50% of the canvas height are inside the viewport
            delay: 1300,      // delay of 500 ms after the canvas is considered inside the viewport
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    };

    const data6 = {
      labels: labels,
      datasets: [{
        label: '',
        backgroundColor: [
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(220, 214, 212)',
          'rgb(88, 26, 78)',
        ],
        borderColor: 'rgb(255, 99, 132)',
        data: [2.06, 2.33, 2.55, 4.08, 4.75],
      }],
    };

    const config6 = {
      type: 'bar',
      data: data6,
      plugins: [ChartDeferred],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y) + 'B';
                }
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
          deferred: {
            yOffset: '70%', // defer until 50% of the canvas height are inside the viewport
            delay: 2500,      // delay of 500 ms after the canvas is considered inside the viewport
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    };

    new Chart(
      document.getElementById('chart-1'),
      config1,
    );

    new Chart(
      document.getElementById('chart-2'),
      config2,
    );

    new Chart(
      document.getElementById('chart-3'),
      config3,
    );

    new Chart(
      document.getElementById('chart-4'),
      config4,
    );

    new Chart(
      document.getElementById('chart-5'),
      config5,
    );

    new Chart(
      document.getElementById('chart-6'),
      config6,
    );
  },
};
